<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Sort the following types of interactions from the strongest down to the weakest.
      </p>
      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items"
        class="mb-0"
        style="width: 500px"
        prepend-text="Strongest"
        append-text="Weakest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput';

export default {
  name: 'Question270',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      items: [
        '$\\text{Dispersion}$',
        '$\\text{Covalent Bond}$',
        '$\\text{Hydrogen Bonding}$',
        '$\\text{Dipole-Dipole}$',
        '$\\text{Dipole-Induced Dipole}$',
      ],
    };
  },
};
</script>
